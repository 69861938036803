import router from '@/plugins/router';
import { apollo } from '@/plugins/apollo';
import USER_ROLE from '@/graphql/queries/UserRole.graphql';
import SCHEDULE_APPROVED from '@/graphql/queries/ScheduleApproved.graphql';
import { SESSION_KEY } from '@/utils/constants';
import { NavigationGuard } from 'vue-router';
import {
  ScheduleApprovedQuery,
  ScheduleApprovedQueryVariables,
  UserQuery,
  UserQueryVariables,
} from '@/types/schema';

export const authGate: NavigationGuard = (to, _from, next) => {
  const token = localStorage.getItem(SESSION_KEY);
  const isPublic = to.matched.some((route) => route.meta.public);
  if (to.name === 'login-form' && token) {
    return next({
      name: 'home',
      replace: true,
    });
  }
  if (isPublic) {
    localStorage.setItem(SESSION_KEY, '');
    return next();
  }
  if (!token) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath },
      replace: true,
    });
  }
  if (to.query.redirect) {
    return next({
      path: String(to.query.redirect),
      replace: true,
    });
  }
  return next();
};

export const roleGate: NavigationGuard = async (to, _from, next) => {
  const isPublic = to.matched.some((route) => route.meta.public);
  const hasRole = to.matched.some((route) => route.meta.roles);
  if (isPublic || (!hasRole && to.name !== 'home')) return next();
  const response = await apollo.query<UserQuery, UserQueryVariables>({
    query: USER_ROLE,
    errorPolicy: 'all',
  });
  const { user } = response.data;
  if (!user) return next({ name: 'logout', replace: true });
  const name = user.role.toLowerCase();
  if (to.name === 'home') return next({ name, replace: true });
  const authorizedUser = to.matched
    .filter((route) => route.meta.roles)
    .some((route) => route.meta.roles.includes(user.role));
  if (!authorizedUser && to.name) {
    // Check if route exists in other roles
    const userRole = user.role.toLowerCase();
    const [routeRole] = to.name.split('-');
    if (userRole === routeRole) return next({ name: 'home', replace: true });
    const [, routeName] = to.name.split(`${routeRole}-`);
    const roleRoute = router.resolve({ name: `${userRole}-${routeName}` });
    if (roleRoute.route.matched.length > 0) {
      return next({
        name: roleRoute.route.name ?? 'home',
        params: { ...to.params },
        query: { ...to.query },
        replace: true,
      });
    }
    return next({ name: 'home', replace: true });
  }
  return next();
};

export const approvedGate: NavigationGuard = async (_to, _from, next) => {
  const response = await apollo.query<
    ScheduleApprovedQuery,
    ScheduleApprovedQueryVariables
  >({
    query: SCHEDULE_APPROVED,
    errorPolicy: 'all',
  });
  const { schedule } = response.data;
  if (schedule?.approved) return next();
  return next({ name: 'home', replace: true });
};
