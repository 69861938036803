import Vue from 'vue';
import Router from 'vue-router';
import { authGate, roleGate, approvedGate } from '@/utils/routerGates';
import { USER_ROLES } from '@/utils/constants';
import { displayName } from '../../package.json';

const RouteComplete = import(
  /* webpackChunkName: "route-complete" */ '@/views/RouteComplete.vue'
);
const DeliveryComplete = import(
  /* webpackChunkName: "delivery-complete" */ '@/views/DeliveryComplete.vue'
);

const RouteStart = import(
  /* webpackChunkName: "route-start" */ '@/views/RouteStart.vue'
);

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () =>
        import(
          /* webpackChunkName: "layout-main" */ '@/layouts/LayoutMain.vue'
        ),
      children: [
        {
          path: '/profile',
          name: 'profile',
          meta: {
            title: 'My Profile',
            roles: [USER_ROLES.ADMIN, USER_ROLES.DRIVER],
          },
          component: () =>
            import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
        },
        {
          path: '/profile/edit',
          name: 'profile-edit',
          meta: {
            title: 'Edit Profile',
            roles: [USER_ROLES.ADMIN, USER_ROLES.DRIVER],
            backTo: { name: 'profile' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "profile-edit" */ '@/views/ProfileEdit.vue'
            ),
        },
      ],
    },
    {
      path: '/',
      name: 'login',
      redirect: { name: 'login-form' },
      meta: { public: true },
      component: () =>
        import(
          /* webpackChunkName: "layout-login" */ '@/layouts/LayoutLogin.vue'
        ),
      children: [
        {
          path: 'login',
          name: 'login-form',
          component: () =>
            import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
        },
        {
          path: 'forgot-password',
          name: 'forgot-password',
          component: () =>
            import(
              /* webpackChunkName: "forgot-password" */ '@/views/ForgotPassword.vue'
            ),
        },
        {
          path: 'reset-password',
          name: 'reset-password',
          component: () =>
            import(
              /* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'
            ),
        },
      ],
    },
    {
      path: '/logout',
      name: 'logout',
      meta: { title: 'Logout' },
      component: () =>
        import(/* webpackChunkName: "logout" */ '@/views/Logout.vue'),
    },
    {
      path: '/',
      name: 'driver',
      redirect: { name: 'driver-dashboard' },
      meta: { roles: [USER_ROLES.DRIVER] },
      component: () =>
        import(
          /* webpackChunkName: "layout-main" */ '@/layouts/LayoutMain.vue'
        ),
      children: [
        {
          path: '',
          name: 'driver-dashboard',
          meta: { title: displayName },
          component: () =>
            import(
              /* webpackChunkName: "driver-dashboard" */ '@/views/DriverDashboard.vue'
            ),
        },
        {
          path: 'route/start',
          name: 'driver-route-start',
          meta: {
            title: 'Start Route',
            backTo: { name: 'home' },
          },
          beforeEnter: approvedGate,
          component: () => RouteStart,
        },
        {
          path: 'route/:id',
          name: 'driver-route',
          meta: {
            backTo: { name: 'home' },
            title: 'Current Route',
            actions: [
              {
                name: 'Add Pick Up',
                icon: 'mdi-plus',
                to: { name: 'driver-route-pickup-add' },
              },
            ],
          },
          beforeEnter: approvedGate,
          component: () =>
            import(
              /* webpackChunkName: "driver-route" */ '@/views/DriverRoute.vue'
            ),
        },
        {
          path: 'route/:id/pickup/add',
          name: 'driver-route-pickup-add',
          meta: {
            title: 'Add Pick Up',
            backTo: { name: 'driver-route' },
            backIcon: 'mdi-close',
          },
          beforeEnter: approvedGate,
          component: () =>
            import(
              /* webpackChunkName: "driver-route-pickup-add" */ '@/views/DriverRoutePickupAdd.vue'
            ),
        },
        {
          path: 'route/:id/pickup/:pickupId',
          name: 'driver-route-pickup-remove',
          meta: {
            title: 'Remove Pick Up',
            backTo: { name: 'driver-route' },
            backIcon: 'mdi-close',
          },
          beforeEnter: approvedGate,
          component: () =>
            import(
              /* webpackChunkName: "driver-route-pickup-remove" */ '@/views/DriverRoutePickupRemove.vue'
            ),
        },
        {
          path: 'route/:id/complete',
          name: 'driver-route-complete',
          meta: {
            title: 'Complete Route',
            backTo: { name: 'driver-route' },
            backIcon: 'mdi-close',
          },
          beforeEnter: approvedGate,
          component: () => RouteComplete,
        },
        {
          path: 'delivery',
          name: 'driver-delivery',
          meta: {
            title: 'Make Delivery',
            backTo: { name: 'home' },
          },
          beforeEnter: approvedGate,
          component: () =>
            import(
              /* webpackChunkName: "driver-delivery" */ '@/views/DriverDelivery.vue'
            ),
        },
        {
          path: 'delivery/:id/change',
          name: 'driver-delivery-change',
          meta: {
            title: 'Change Dairy',
            backTo: { name: 'driver-delivery' },
            backIcon: 'mdi-close',
          },
          beforeEnter: approvedGate,
          component: () =>
            import(
              /* webpackChunkName: "driver-delivery-change" */ '@/views/DriverDeliveryChange.vue'
            ),
        },
        {
          path: 'delivery/:id/complete',
          name: 'driver-delivery-complete',
          meta: {
            title: 'Complete Delivery',
            backTo: { name: 'driver-delivery' },
            backIcon: 'mdi-close',
          },
          beforeEnter: approvedGate,
          component: () => DeliveryComplete,
        },
        {
          path: 'schedule/:date?',
          name: 'driver-schedule',
          meta: { title: 'Weekly Schedule' },
          component: () =>
            import(
              /* webpackChunkName: "driver-schedule" */ '@/views/DriverSchedule.vue'
            ),
        },
        {
          path: 'expenses',
          name: 'driver-expenses',
          meta: {
            title: 'Additional Pay',
            actions: [
              {
                name: 'Add Expense',
                icon: 'mdi-plus',
                to: { name: 'driver-expenses-add' },
              },
            ],
          },
          component: () =>
            import(
              /* webpackChunkName: "driver-expenses" */ '@/views/DriverExpenses.vue'
            ),
        },
        {
          path: 'expenses/add',
          name: 'driver-expenses-add',
          meta: {
            title: 'Add Expense',
            backTo: { name: 'driver-expenses' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "driver-expenses-add" */ '@/views/DriverExpensesAdd.vue'
            ),
        },
        {
          path: 'expenses/:id',
          name: 'driver-expenses-details',
          meta: {
            title: 'Expense Details',
            backTo: { name: 'driver-expenses' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "driver-expenses-details" */ '@/views/DriverExpensesDetails.vue'
            ),
        },
        {
          path: 'expenses/:id/edit',
          name: 'driver-expenses-edit',
          meta: {
            title: 'Edit Expense',
            backTo: { name: 'driver-expenses-details' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "driver-expenses-add" */ '@/views/DriverExpensesAdd.vue'
            ),
        },
      ],
    },
    {
      path: '/',
      name: 'admin',
      redirect: { name: 'admin-dashboard' },
      meta: { roles: [USER_ROLES.ADMIN] },
      component: () =>
        import(
          /* webpackChunkName: "layout-main" */ '@/layouts/LayoutMain.vue'
        ),
      children: [
        {
          path: '',
          name: 'admin-dashboard',
          meta: { title: `${displayName} Admin` },
          component: () =>
            import(
              /* webpackChunkName: "admin-dashboard" */ '@/views/AdminDashboard.vue'
            ),
        },
        {
          path: 'schedules/:date?',
          name: 'admin-schedules',
          meta: { title: 'Schedules' },
          component: () =>
            import(
              /* webpackChunkName: "admin-schedules" */ '@/views/AdminSchedules.vue'
            ),
        },
        {
          path: 'schedules/:date/add',
          name: 'admin-schedules-add',
          meta: {
            title: 'Add Item',
            backTo: { name: 'admin-schedules' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-schedules-add" */ '@/views/AdminSchedulesAdd.vue'
            ),
        },
        {
          path: 'reports',
          name: 'admin-reports',
          meta: { title: 'Reports' },
          component: () =>
            import(
              /* webpackChunkName: "admin-reports" */ '@/views/AdminReports.vue'
            ),
          children: [
            {
              path: 'payroll',
              name: 'admin-reports-payroll',
              meta: {
                title: 'Payroll Report',
                backTo: { name: 'admin-reports' },
                backIcon: 'mdi-close',
              },
              component: () =>
                import(
                  /* webpackChunkName: "admin-reports-payroll" */ '@/views/AdminReportsPayroll.vue'
                ),
            },
            {
              path: 'detention',
              name: 'admin-reports-detention',
              meta: {
                title: 'Detention Report',
                backTo: { name: 'admin-reports' },
                backIcon: 'mdi-close',
              },
              component: () =>
                import(
                  /* webpackChunkName: "admin-reports-detention" */ '@/views/AdminReportsDetention.vue'
                ),
            },
          ],
        },
        {
          path: 'routes',
          name: 'admin-routes',
          meta: {
            title: 'Routes',
            actions: [
              {
                name: 'Add Route',
                icon: 'mdi-plus',
                to: { name: 'admin-routes-add' },
              },
            ],
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-routes" */ '@/views/AdminRoutes.vue'
            ),
        },
        {
          path: 'route/:id/start',
          name: 'admin-route-start',
          meta: {
            title: 'Start Route',
            backTo: { name: 'admin-schedules' },
          },
          component: () => RouteStart,
        },
        {
          path: 'routes/add',
          name: 'admin-routes-add',
          meta: {
            title: 'Add Route',
            backTo: { name: 'admin-routes' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-routes-add" */ '@/views/AdminRoutesAdd.vue'
            ),
        },
        {
          path: 'routes/:id',
          name: 'admin-routes-edit',
          meta: {
            title: 'Edit Route',
            backTo: { name: 'admin-routes' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-routes-add" */ '@/views/AdminRoutesAdd.vue'
            ),
        },
        {
          path: 'route/:id/complete',
          name: 'admin-route-complete',
          meta: {
            title: 'Complete Route',
            backTo: { name: 'admin-schedules' },
            backIcon: 'mdi-close',
          },
          component: () => RouteComplete,
        },
        {
          path: 'delivery/:id/complete',
          name: 'admin-delivery-complete',
          meta: {
            title: 'Complete Delivery',
            backTo: { name: 'admin-schedules' },
            backIcon: 'mdi-close',
          },
          component: () => DeliveryComplete,
        },
        {
          path: 'trailers',
          name: 'admin-trailers',
          meta: {
            title: 'Trailers',
            actions: [
              {
                name: 'Add Trailer',
                icon: 'mdi-plus',
                to: { name: 'admin-trailers-add' },
              },
            ],
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-trailers" */ '@/views/AdminTrailers.vue'
            ),
        },
        {
          path: 'trailers/add',
          name: 'admin-trailers-add',
          meta: {
            title: 'Add Trailer',
            backTo: { name: 'admin-trailers' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-trailers-add" */ '@/views/AdminTrailersAdd.vue'
            ),
        },
        {
          path: 'trailers/:id',
          name: 'admin-trailers-edit',
          meta: {
            title: 'Edit Trailer',
            backTo: { name: 'admin-trailers' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-trailers-add" */ '@/views/AdminTrailersAdd.vue'
            ),
        },
        {
          path: 'trucks',
          name: 'admin-trucks',
          meta: {
            title: 'Trucks',
            actions: [
              {
                name: 'Add Truck',
                icon: 'mdi-plus',
                to: { name: 'admin-trucks-add' },
              },
            ],
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-trucks" */ '@/views/AdminTrucks.vue'
            ),
        },
        {
          path: 'trucks/add',
          name: 'admin-trucks-add',
          meta: {
            title: 'Add Truck',
            backTo: { name: 'admin-trucks' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-trucks-add" */ '@/views/AdminTrucksAdd.vue'
            ),
        },
        {
          path: 'trucks/:id',
          name: 'admin-trucks-edit',
          meta: {
            title: 'Edit Truck',
            backTo: { name: 'admin-trucks' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-trucks-add" */ '@/views/AdminTrucksAdd.vue'
            ),
        },
        {
          path: 'farms',
          name: 'admin-farms',
          meta: {
            title: 'Farms',
            actions: [
              {
                name: 'Add Farm',
                icon: 'mdi-plus',
                to: { name: 'admin-farms-add' },
              },
            ],
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-farms" */ '@/views/AdminFarms.vue'
            ),
        },
        {
          path: 'farms/add',
          name: 'admin-farms-add',
          meta: {
            title: 'Add Farm',
            backTo: { name: 'admin-farms' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-farms-add" */ '@/views/AdminFarmsAdd.vue'
            ),
        },
        {
          path: 'farms/:id',
          name: 'admin-farms-edit',
          meta: {
            title: 'Edit Farm',
            backTo: { name: 'admin-farms' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-farms-add" */ '@/views/AdminFarmsAdd.vue'
            ),
        },
        {
          path: 'dairies',
          name: 'admin-dairies',
          meta: {
            title: 'Dairies',
            actions: [
              {
                name: 'Add Dairy',
                icon: 'mdi-plus',
                to: { name: 'admin-dairies-add' },
              },
            ],
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-dairies" */ '@/views/AdminDairies.vue'
            ),
        },
        {
          path: 'dairies/add',
          name: 'admin-dairies-add',
          meta: {
            title: 'Add Dairy',
            backTo: { name: 'admin-dairies' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-dairies-add" */ '@/views/AdminDairiesAdd.vue'
            ),
        },
        {
          path: 'dairies/:id',
          name: 'admin-dairies-edit',
          meta: {
            title: 'Edit Dairy',
            backTo: { name: 'admin-dairies' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-dairies-add" */ '@/views/AdminDairiesAdd.vue'
            ),
        },
        {
          path: 'users',
          name: 'admin-users',
          meta: {
            title: 'Users',
            actions: [
              {
                name: 'Add User',
                icon: 'mdi-plus',
                to: { name: 'admin-users-add' },
              },
            ],
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-users" */ '@/views/AdminUsers.vue'
            ),
        },
        {
          path: 'users/add',
          name: 'admin-users-add',
          meta: {
            title: 'Add User',
            backTo: { name: 'admin-users' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-users-add" */ '@/views/AdminUsersAdd.vue'
            ),
        },
        {
          path: 'users/:id',
          name: 'admin-users-edit',
          meta: {
            title: 'Edit User',
            backTo: { name: 'admin-users' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-users-add" */ '@/views/AdminUsersAdd.vue'
            ),
        },
        {
          path: 'users/:id/rate',
          name: 'admin-users-pay-rate',
          meta: {
            title: 'Set User Pay Rate',
            backTo: { name: 'admin-users' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-users-pay-rate" */ '@/views/AdminUsersPayRate.vue'
            ),
        },
        {
          path: 'expenses',
          name: 'admin-expenses',
          meta: { title: 'Additional Pay' },
          component: () =>
            import(
              /* webpackChunkName: "admin-expenses" */ '@/views/AdminExpenses.vue'
            ),
        },
        {
          path: 'expenses/:id',
          name: 'admin-expenses-details',
          meta: {
            title: 'Expense Details',
            backTo: { name: 'admin-expenses' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-expenses-details" */ '@/views/AdminExpensesDetails.vue'
            ),
        },
        {
          path: 'coops',
          name: 'admin-coops',
          meta: {
            title: 'Co-ops',
            actions: [
              {
                name: 'Add Co-op',
                icon: 'mdi-plus',
                to: { name: 'admin-coops-add' },
              },
            ],
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-coops" */ '@/views/AdminCoops.vue'
            ),
        },
        {
          path: 'coops/add',
          name: 'admin-coops-add',
          meta: {
            title: 'Add Co-op',
            backTo: { name: 'admin-coops' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-coops-add" */ '@/views/AdminCoopsAdd.vue'
            ),
        },
        {
          path: 'coops/:id',
          name: 'admin-coops-edit',
          meta: {
            title: 'Edit Co-op',
            backTo: { name: 'admin-coops' },
            backIcon: 'mdi-close',
          },
          component: () =>
            import(
              /* webpackChunkName: "admin-coops-add" */ '@/views/AdminCoopsAdd.vue'
            ),
        },
      ],
    },
    {
      path: '/',
      name: 'coop',
      redirect: { name: 'coop-farm-tickets' },
      meta: { roles: [USER_ROLES.COOP] },
      component: () =>
        import(
          /* webpackChunkName: "layout-main" */ '@/layouts/LayoutMain.vue'
        ),
      children: [
        {
          path: '/',
          name: 'coop-farm-tickets',
          meta: { title: 'Farm Tickets' },
          component: () =>
            import(
              /* webpackChunkName: "coop-farm-tickets" */ '@/views/CoopFarmTickets.vue'
            ),
        },
      ],
    },
    {
      path: '*',
      redirect: { name: 'home' },
    },
  ],
});

router.beforeEach(authGate);
router.beforeEach(roleGate);

export default router;
