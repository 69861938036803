import Vue from 'vue';

export const state = Vue.observable({
  hasUpdate: false,
});

export const updateAvailable = () => {
  state.hasUpdate = true;
};

export const triggerUpdate = () => {
  window.location.reload();
};
