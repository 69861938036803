import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
import { ApolloLink } from 'apollo-link';
import { onError, ErrorHandler } from 'apollo-link-error';
import router from '@/plugins/router';
import { SESSION_KEY, CODES } from '@/utils/constants';
import { GraphQLError } from 'graphql';

Vue.use(VueApollo);

const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || '/graphql';

const afterwareLink = new ApolloLink((operation, forward) => {
  if (!forward) return null;

  return forward(operation).map((response) => {
    const context = operation.getContext();
    const { headers } = context.response;
    if (headers) {
      const token = headers.get('token');
      if (token) localStorage.setItem(SESSION_KEY, token);
    }
    return response;
  });
});

const errorHandler: ErrorHandler = ({ graphQLErrors }) => {
  if (!graphQLErrors) return;
  const hasExtensions = ({ extensions }: GraphQLError) => !!extensions;
  const requireAuth = ({ extensions }: GraphQLError) =>
    extensions && extensions.code === CODES.TOKEN_INVALID;
  if (graphQLErrors.filter(hasExtensions).some(requireAuth)) {
    router.push({ name: 'logout', query: { expired: '1' } });
  }
};

const errorLink = onError(errorHandler);

const link = afterwareLink.concat(errorLink);

const { apolloClient } = createApolloClient({
  httpEndpoint,
  tokenName: SESSION_KEY,
  link,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorHandler: errorHandler as any, // These types don't line up
});

export const apollo = apolloProvider.defaultClient;

export default apolloProvider;
