import Vue from 'vue';
import Rollbar from 'vue-rollbar';
import { version } from '../../package.json';

Vue.use(Rollbar, {
  accessToken: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  verbose: true,
  enabled: process.env.NODE_ENV === 'production',
  payload: {
    client: {
      javascript: {
        code_version: version,
      },
    },
  },
});
