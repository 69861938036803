import '@babel/runtime/regenerator';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/plugins/router';
import apolloProvider from '@/plugins/apollo';
import vuetify from '@/plugins/vuetify';
import '@/plugins/notifications';
import '@/plugins/vuelidate';
import '@/plugins/hammer';
import '@/plugins/rollbar';
import '@/registerServiceWorker';
import { updateAvailable } from '@/utils/swHelper';

Vue.config.productionTip = false;

new Vue({
  name: 'Root',
  router,
  apolloProvider,
  vuetify,
  created() {
    document.addEventListener('swUpdated', updateAvailable);
  },
  render: (h) => h(App),
}).$mount('#app');
