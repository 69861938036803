import {
  Role,
  RouteStatus,
  DeliveryStatus,
  ExpenseStatus,
} from '@/types/schema';

export const SESSION_KEY = 'day-transport-session';

export const CODES = Object.freeze({
  TOKEN_INVALID: 'TOKEN_INVALID',
  UNAUTHORIZED: 'UNAUTHORIZED',
  NOT_FOUND: 'NOT_FOUND',
} as const);

export const USER_ROLES = Object.freeze({
  ADMIN: 'ADMIN' as Role.Admin,
  DRIVER: 'DRIVER' as Role.Driver,
  COOP: 'COOP' as Role.Coop,
});

export const ROUTE_STATUSES = Object.freeze({
  UNSTARTED: 'UNSTARTED' as RouteStatus.Unstarted,
  STARTED: 'STARTED' as RouteStatus.Started,
  COMPLETED: 'COMPLETED' as RouteStatus.Completed,
});

export const DELIVERY_STATUSES = Object.freeze({
  INCOMPLETE: 'INCOMPLETE' as DeliveryStatus.Incomplete,
  COMPLETED: 'COMPLETED' as DeliveryStatus.Completed,
});

export const EXPENSE_STATUSES = Object.freeze({
  PENDING: 'PENDING' as ExpenseStatus.Pending,
  APPROVED: 'APPROVED' as ExpenseStatus.Approved,
  DENIED: 'DENIED' as ExpenseStatus.Denied,
});

export const SCHEDULE_TYPES = Object.freeze({
  ROUTE: 'Route',
  DELIVERY: 'Delivery',
} as const);
